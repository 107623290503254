<template>
  <div class="container py-4 py-lg-5">
    <LottieBackground
      v-if="group"
      :preferredCategoryID="group.PersonActivityGroupSkillCategoryID"
      :preferredNo="2"
    />

    <div class="row">
        <div class="col">
          <b-button variant="primary" :to="{ name: 'childhome', params: { student_id: $route.params.student_id } }" class="mb-2 mb-lg-3">
            <font-awesome-icon icon="angle-left" fixed-width/>
            {{ $t("context.back_to_stickerbooks") }}
          </b-button>
        </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card col-xs-12 col-lg-6">
          <div class="card-body" v-if="group">
            <h1 class="h2">{{ decodeHTML($t('context.child_viewing_skill', { name: student.Name })) }}</h1>
            <div class="float-right">
              <TheMascotte
                :preferredCategoryID="group.PersonActivityGroupSkillCategoryID"
                :preferredNo="2"
              />
            </div>
          </div>
        </div>

        <div class="card stickerbook mt-5">
          <div class="card-header">
            <div
              class="sticker animate__animated animate__bounceIn"
            >
              <img :src="group.SkillCategory.Image" class="image completed" v-if="group && group.SkillCategory && group.SkillCategory.Image" />
            </div>
            <div class="text">
              <h5 class="mb-0" v-if="group">
                {{ decodeHTML($t('context.stickerbook_for_group', { group: group.Name })) }}
              </h5>
            </div>
          </div>
          <div class="card-body">
            <div v-if="skillCategory && skillCategory.Skills && skillCategory.Skills.length" class="skills-grid">
              <div v-for="(skill, index) in skillCategory.Skills" :key="`skill_${skill['Skill.SkillID']}`">
                <TheSticker
                  class="sticker-wrap flex"
                  :student="student"
                  :group="group"
                  :image="skill['Skill.Image'] ? skill['Skill.Image'] : false"
                  :skill="skill"
                  :index="index"
                />
              </div>
              <TheSticker
                class="sticker-wrap flex"
                :index="skillCategory.Skills.length"
                :grain="true"
                :image="group.SkillCategory.Image ? group.SkillCategory.Image : false"
                :allComplete="allComplete"
                :link="false"
              />
            </div>
            <div v-else>
              <p>{{ decodeHTML($t('context.no_stickers_found_yet')) }}</p>
            </div>
          </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import TheMascotte from '@/components/TheMascotte'
import TheSticker from '@/components/TheSticker'
import LottieBackground from '@/components/LottieBackground'
import skillsMixin from '@/util/skillsMixin'
import he from 'he'

export default {
  name: 'StickerBook',

  mixins: [skillsMixin],

  components: {
    TheMascotte,
    TheSticker,
    LottieBackground
  },

  computed: {
    group () {
      if (this.student && this.student.ActivityGroup) {
        return this.student.ActivityGroup.find(group => group.ActivityGroupID === this.$route.params.group_id)
      }

      return false
    }
  },

  methods: {
    decodeHTML (text) {
      return he.decode(text)
    }
  }
}
</script>
